import { render, staticRenderFns } from "./NewIQCQuery.vue?vue&type=template&id=369d02ef&scoped=true"
import script from "./NewIQCQuery.vue?vue&type=script&lang=js"
export * from "./NewIQCQuery.vue?vue&type=script&lang=js"
import style0 from "./NewIQCQuery.vue?vue&type=style&index=0&id=369d02ef&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369d02ef",
  null
  
)

export default component.exports